import React, { useState, useCallback, useEffect, useContext } from "react";
import { Container } from "reactstrap";
import { getUrlParameter } from "../../constants/commonFunction";
import { regulatoryFormFields } from "../../constants/regulatorDetailsConstants";
import DropdownField from "../../components/CommonDropDown/Dropdown";
import InputField from "../../components/CommonInput/Input";
import AcceptButtons from "../OpenCasesDetailsPage/AcceptButtons";
import { useNavigate, useLocation} from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { makeSelectData } from "../SubmitFinalPage/selectors";
import { connect } from "react-redux";
import { ApiDispatchContext } from "../../context/ApiContext";
import ErrorAlert from "../../components/ErrorAlert";
import SuccessMessage from "../../components/SuccessMssgDisplay";
import path from "../../constants/pathConstants";
import Loader from "../../components/Loader";

let formFieldsToValidate = [
  "ground_of_fraud_rejection",
  "fraud_committed_by",
  "name_of_suspect",
  "suspect_idproof_name",
  "suspect_idproof_number",
  "investigation_agency_name",
  "investigator_pan_card",
  "investigator_gst_num",
];

const RegulatoryDetails = ({data}) => {
  const api = useContext(ApiDispatchContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {getDetails , ground_of_fraud_rejection, caseid} = location.state
  const [disabled, setDisabled] = useState(true);
  const [modal, setModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [regulatoryDetails, setRegulatoryDetails] = useState({
    ...getDetails,
    ground_of_fraud_rejection: ground_of_fraud_rejection,
    fraud_committed_by: getDetails?.fraud_committed_by || "",
    name_of_suspect: getDetails?.name_of_suspect || "",
    suspect_idproof_name: getDetails?.suspect_idproof_name || "",
    suspect_idproof_number: getDetails?.suspect_idproof_number||"",
    investigation_agency_name: getDetails?.investigation_agency_name ||[],
    investigator_pan_card: getDetails?.investigator_pan_card||"",
    investigator_gst_num:getDetails?.investigator_gst_num||"",  
  });
  const toggle = () => setModal(prev=>!prev);
  let casetype = getUrlParameter("casetype");
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    //adding key for fraud commiteed by select array conditionally
    const addKey = e.target.localName ==="select" ? regulatoryFormFields[1].parameter_options.find(option => option.label === value)?.value: undefined
    setRegulatoryDetails((prev) => {
      const updatedData = addKey ? {...prev,[name]:value, [addKey]: prev[addKey]||""}: { ...prev, [name]: value };
      return updatedData;
    });
  }, []);

  const onBackClick = () => {
    navigate(-1);
  };

  const redirectAssignCase = () => {
    switch (casetype) {
      case "opencases":
        return path.OPEN_CASE;
      case "allottedcase":
        return path.ALLOTTED_CASES;
      default:
        return path.REINVESTIGATE_CASES;
    }
  }

  const submitFinalCase = ()=>{
    let payload = {
      "caseId": caseid,
      "gradeMasterId": data[0].id,
      "gradeStatusId": data[1].id,
      "gradeSubStatusId": data[2].id,
      "closureCategory": data[3].parameter_value,
      "comment": data[4].parameter_value
    }
   return api.submitGradeCase(payload).then(data => {
    setShowLoader(false);
      if (data.data.success) {
       return navigate(
            path.SUCCESS_PAGE,
            {state: { stateVal: redirectAssignCase()}}
          )
      } else {
        setModalTitle("Failed to load");
        if (data.data.error && data.data.message) {
          setModalBody(data.data.message)
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setShowLoader(false);
      setModalTitle("Failed to Save Data.");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...getDetails,
      ...regulatoryDetails,
      case_id: caseid||null,
    };
    setShowLoader(true);
    api.storeRegulatoryDetails(payload).then((data)=>{
      if(data.data.success){
        //call submit final
       return submitFinalCase()
      } else {
        console.log("else",error)
        setShowLoader(false);
        setModalTitle("Failed to submit regulatory details");
        if (data?.data?.message) {
          setModalBody(data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      }
    }).catch((err)=>{
      console.log(err, data)
      setShowLoader(false);
      setModalTitle("Failed to submit regulatory details");
        if (err && err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
    })
    setDisabled(false);
  };

  const getOptions = (item) => {
    if (item?.name === "investigation_agency_name") {
      const agencyName = getDetails?.investigation_agency_name;
      return typeof agencyName === "string"
        ? [agencyName] 
        : Array.isArray(agencyName)
        ? [...agencyName] 
        : []; 
    } else {
      return item?.parameter_options?.map((option) => option.label) || [];
    }
  };

  function borderColor() {
    return casetype === "opencase"
      ? " 1px solid #1AB275"
      : casetype === "allottedcase"
      ? "1px solid #058AEE"
      : "1px solid #EBAF00";
  }
  function backgroundColor() {
    return casetype === "opencase"
      ? "rgba(26, 178, 117, 0.1)"
      : casetype === "allottedcase"
      ? "rgba(5, 138, 238, 0.1)"
      : "rgba(235, 175, 0, 0.1)";
  }
  useEffect(()=>{ //validate form
      if(regulatoryDetails["fraud_committed_by"]) {
        const valueToPush = regulatoryFormFields[1].parameter_options.find(option => option.label === regulatoryDetails["fraud_committed_by"])?.value;
      if (valueToPush) {
      // Remove any previously selected dropdown value before adding the new one.
        const updatedFormFields = formFieldsToValidate.filter(val => {
          return regulatoryFormFields[1].parameter_options.findIndex(
            option => option.value === val
          ) === -1;
        })
          // Then, push the new value into the updated array
          updatedFormFields.push(valueToPush);
          formFieldsToValidate = [...updatedFormFields]
        }
      }
      const isAllFieldFilled = (regulatoryDetails) => {
        return formFieldsToValidate.every(
          (key) => regulatoryDetails[key] !== null && regulatoryDetails[key] !== undefined && regulatoryDetails[key] !== ""
        );
      }
      setDisabled(!isAllFieldFilled(regulatoryDetails));
  },[regulatoryDetails])
  return (
    <Container className="mt-5">
      <div className="submit-final">
        <span>Regulatory Details</span>
        <div
          style={{ border: borderColor() }}
          className="submitfinal-container"
        >
          {regulatoryFormFields?.map((item, i) => {
            switch (item?.parameter_type) {
              case "select":
                return <React.Fragment key={item.block_parameters_id}>
                 <DropdownField
                    id={item?.block_parameters_id}
                    key={item?.block_parameters_id}
                    label={item?.parameter_name}
                    placeholder={"Please select" + " " + item?.parameter_name}
                    options={getOptions(item)}
                    backgroundColor={backgroundColor}
                    onChange={handleInputChange}
                    value={regulatoryDetails[item?.name]}
                    name={item.name}
                  />
                 {item?.name === "fraud_committed_by" && regulatoryDetails[item?.name] && (
                      <InputField
                        id={item?.block_parameters_id}
                        placeholder={"Please enter"}
                        label={regulatoryDetails["fraud_committed_by"]}
                        name={item?.parameter_options.find(option => option.label === regulatoryDetails["fraud_committed_by"])?.value}  //console log regulatoryDetails to understand this
                        onChange={handleInputChange} 
                        value={regulatoryDetails[item?.parameter_options.find(option => option.label === regulatoryDetails["fraud_committed_by"])?.value]} 
                        backgroundColor={backgroundColor}
                      />
                    )}        
                </React.Fragment>
              case "text":
                return (
                  <React.Fragment key={item?.block_parameters_id}>
                    <InputField
                      id={item?.block_parameters_id}
                      label={item?.parameter_name}
                      name={item?.name}
                      placeholder={"Please select" + " " + item?.parameter_name}
                      onChange={handleInputChange}
                      value={regulatoryDetails[item?.name]}
                      backgroundColor={backgroundColor}
                      disabled={item.name === "ground_of_fraud_rejection"} 
                    />
                  </React.Fragment>
                );
            }
          })}
        </div>
      </div>
      <div
        style={{
          position: "sticky",
          bottom: "65px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <AcceptButtons
          background={"rgb(233, 77, 81)"}
          color={"rgb(255, 255, 255)"}
          onClick={onBackClick}
        >
          Back
        </AcceptButtons>
        <AcceptButtons
          disabled={disabled}
          background={disabled ? "#F7BDBE" : "rgb(233, 77, 81)"}
          color={!disabled ? "rgb(255, 255, 255)" : "#E42125"}
          onClick={(e) => onSubmit(e)}
          datatestid="save-button"
        >
          Submit
        </AcceptButtons>
      </div>
      {/* toasters */}
      {showLoader && <Loader />}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  data: makeSelectData(),
});


export default connect(mapStateToProps)(RegulatoryDetails);
