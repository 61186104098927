import { createSelector } from "reselect";
import { initialState } from "./reducer";

/**
 * Direct selector to the allottedCasesDetailsPage state domain
 */

const selectAllottedCasesDetailsPageDomain = state =>
  state.allottedCasesDetailsPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by AllottedCasesDetailsPage
 */

const makeSelectAllottedCasesDetailsPage = () =>
  createSelector(
    selectAllottedCasesDetailsPageDomain,
    substate => substate
  );

const makeSelectCaseData = () =>
createSelector(
  selectAllottedCasesDetailsPageDomain,
  substate => substate.caseDetails
);

export default makeSelectAllottedCasesDetailsPage;
export { selectAllottedCasesDetailsPageDomain,makeSelectCaseData };
