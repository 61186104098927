import { CASEREINVESTIGATEDETAILS, DEFAULT_ACTION } from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function caseReInvestigateDetails(payload) {
  return {
    type: CASEREINVESTIGATEDETAILS,
    payload:payload
  };
}
