export const regulatoryFormFields = [
  {
    block_parameters_id: 1,
    parameter_name: "Ground of Fraud Rejection",
    parameter_type: "text",
    name: "ground_of_fraud_rejection",
    parameter_value: "",
  },
  {
    block_parameters_id: 2,
    parameter_name: "Fraud Committed by",
    parameter_type: "select",
    name: "fraud_committed_by",
    parameter_options:[
      { id: 1, label: "Claimant", value: "claimant" },
      { id: 2, label: "Hospital", value: "hospital" },
      { id: 3, label: "Doctor", value: "doctor" },
      { id: 4, label: "Diagnostic Center", value: "diagnostic_center" },
      { id: 5, label: "Pathologist", value: "pathologist" },
      { id: 6, label: "Intermediary", value: "intermediary" },
      { id: 7, label: "Agency", value: "agency" },
      { id: 8, label: "Insured", value: "insured" },
      { id: 8, label: "Driver", value: "driver" },
      { id: 8, label: "Petitioners", value: "petitioners" },
    ],
    parameter_value: "",
  },
  {
    block_parameters_id: 3,
    parameter_name: "Name of Suspect/Fraudster",
    parameter_type: "text",
    name: "name_of_suspect",
    parameter_value: "",
  },
  {
    block_parameters_id: 4,
    parameter_name: "Suspect/Fraudster ID Proof Name",
    parameter_type: "select",
    name: "suspect_idproof_name",
    parameter_options: [
      {
        id: 9,
        label: "Pan Card",
      },
      {
        id: 10,
        label: "Passport",
      },
      {
        id: 11,
        label: "Voter ID",
      },
      {
        id: 12,
        label: "Driving License",
      },
    ],
    parameter_value: "",
  },
  {
    block_parameters_id: 5,
    parameter_name: "Suspect/Fraudster ID Proof Number",
    parameter_type: "text",
    name: "suspect_idproof_number",
    parameter_value: "",
  },
  {
    block_parameters_id: 6,
    parameter_name: "Investigation Agency Name",
    parameter_type: "select",
    name: "investigation_agency_name",
    parameter_options: [],
    parameter_value: "",
  },
  {
    block_parameters_id: 7,
    parameter_name: "Investigator PAN Card",
    parameter_type: "text",
    name: "investigator_pan_card",
    parameter_value: "",
  },
  {
    block_parameters_id: 8,
    parameter_name: "Investigator GSTN Number",
    parameter_type: "text",
    name: "investigator_gst_num",
    parameter_value: "",
  },
];
