/*
 *
 * AllottedCasesDetailsPage reducer
 *
 */
import produce from "immer";
import { CASEDETAILS, DEFAULT_ACTION } from "./constants";

export const initialState = {
  
};

/* eslint-disable default-case, no-param-reassign */
const allottedCasesDetailsPageReducer = (state = initialState, action) =>
  produce(state, draft => {
  switch (action.type) {
    case DEFAULT_ACTION:
      break;
    case CASEDETAILS:
      draft.caseDetails = action.payload
      break;
  }
  });

export default allottedCasesDetailsPageReducer;
