module.exports = {
    HOME: "/",
    OTP: "/otp",
    MOBILE:"/Mobile",
    DASHBOARD: "/dashboard",
    MESSAGE:"/message",
    NOTIFICATION:"/notification",
    OPEN_CASE:"/opencases",
    OPEN_CASE_DETAILS:"/opencases/details",
    ALLOTED_CASE_DETAILS:"/allottedcases/details",
    REINVESTIGATE_CASES_DETAILS:"/reinvestigatecases/details",
    CASEDETAIL:"/casedetail",
    ERROR: "/error",
    EDITEMPLOYEE_DETAILS: "/editemployeedetails",
    EMPLOYMENT_MASTER: "/employment-master",
    ALLOTTED_CASES:"/allottedcases",
    REINVESTIGATE_CASES:"/reinvestigatecases",
    REJECTED_INVOICES:"/rejectedinvoices",
    EMPLOYEMENT_SEARCH:"/employement-search",
    EMPLOYEMENT_CHANGESTATUS:"/employement-changestatus",
    EMPLOYEMENT_SUCCESS:"/employement-success",
    ADD_INVESTIGATOR:"/add-investigator",
    ONBOARDING:"/onboarding",
    ASSIGN_INVESTIGATOR: "/assign-investigator",
    ASSIGN_INVESTIGATOR_SUCCESS: "/assign-investigator-success",
    ASSIGNED_BLOCCKS_ALLOTTED_CASES: '/assignedblocks-allottedcases',
    ASSIGNED_BLOCKS_OPEN_CASES: '/assignedblocks-opencases',
    ASSIGNED_BLOCKS_REINVESTIGATED_CASES:"/assignedblocks-reinvestigatedcases",
    REJECTED_INVOICES_DETAILS: "/rejectedInvoices-details",
    ADD_NEW_LOCATION: "/add-NewLocation",
    ADD_NEW_INVOICE:"/add-new-invoice",
    SUBMIT_FINAL:"/submitfinal",
    UPLOAD_INVOICE:"/upload-invoice",
    PREVIEW_UPLOADED_INVOICES:"/preview-uploaded-invoice",
    UPLOAD_INVESTIGATION_REPORT:"/upload-investigation-report",
    GENERATE_FACT_FINDING_REPORT:'/generate-fact-finding-report',
    REVIEW_REPORT:"/review-report",
    UPLOAD_INVOICE_DETAILS:"/upload-invoice-details",
    COMING_SOON:"/coming-soon",
    PERFORMANCE_REPORT:"/performance-report",
    SUCCESS_PAGE:"/success-page",
    PROFILE_PAGE: "/profile",
    SEARCH_CASE: "/search/case",
    SERVICE_LOCATIONS: "/profile/serviceLocations",
    DOCUMENT_CENTER: "/profile/documentCenter",
    PROFILE_VIEW_DOC: "/profile/documentCenter/viewDocument",
    CASES_AND_INVOICES_TRACKER: "/profile/case-and-invoice-tracker",
    DO_NOT_ACCEPT_CASE: "/do-not-accept-case",
    DO_NOT_ACCEPT_SUCCESS: "/do-not-accept-success",
    PAGE_NOT_FOUND: "/pageNotFound",
    INVOICE_DETAILS_AND_STATUS: "/invoice-details-and-status",
    REGULATORY_DETAILS:"/regulatory-details"
}