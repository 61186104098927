/*
 *
 * AllottedCasesDetailsPage actions
 *
 */

import { CASEDETAILS, DEFAULT_ACTION } from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function caseDetails(payload) {
  return {
    type: CASEDETAILS,
    payload:payload
  };
}
