import React from "react";
import { Label, Input } from "reactstrap";

const DropdownField = ({
  id,
  name,
  label,
  value,
  options,
  placeholder,
  backgroundColor,
  onChange = () => {},
}) => (
  <div className="dd submit-dropdown" style={{ color: "red" }}>
    <Label for={id} className={"dd label-text"}>
      {label}
    </Label>
    <Input
      type="select"
      id={id}
      style={{
        backgroundColor: backgroundColor(),
        fontWeight: "400",
        fontSize: "12px",
        color: "#3a3a3a",
      }}
      onChange={onChange}
      value={value}
      name={name}
    >
      <option value="">{placeholder}</option>
      {options.map((option, index) => (
        <option className="generic-dropdownitems" key={index} value={option}>
          {option}
        </option>
      ))}
    </Input>
  </div>
);

export default DropdownField;
