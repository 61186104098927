import produce from "immer";
import { CASEREINVESTIGATEDETAILS, DEFAULT_ACTION } from "./constants";
export const initialState = {};

const reinvestigateCasesDetailsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case CASEREINVESTIGATEDETAILS:
        draft.caseReinvestigateDetails = action.payload
        break;
    }
  });

export default reinvestigateCasesDetailsReducer;
