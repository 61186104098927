import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const InputField = ({
  id,
  name,
  label,
  value,
  placeholder,
  onChange = () => {},
  backgroundColor,
  disabled = false
}) => (
  <div className="textinput-genric-submit dd submit-dropdown">
    <FormGroup>
      <Label for={id} className="label-text">
        {label}
      </Label>
      <Input
        type="text"
        id={id}
        name={name}
        className="Genericforms-input"
        style={{ backgroundColor: backgroundColor() }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </FormGroup>
  </div>
);

export default InputField;
