/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from "react";
import { Helmet } from "react-helmet";
import './index.css';
import { Routes, Route } from "react-router-dom";
import {PrivatePath} from "./PrivatePath";
import HomePage from "../HomePage/Loadable";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import OtpPage from "../OtpPage/Loadable";
import Mobile from "../Mobile/Loadable";
import Dashboard from "../dashboard/Loadable";
import EditEmployeeDetails from "../EditEmployeeDetails/Loadable";
import GlobalStyle from "../../global-styles";
import path from "../../constants/pathConstants"
import NavBar from '../../components/NavBar';
import Message from '../../components/Message';
import Notification from '../../components/Notification';
import CasesDetails from '../OpenCases';
import EmploymentMasterPage from '../EmploymentMasterPage/Loadable';
import SearchEmpPage from '../EmploymentMasterPage/SearchEmpPage';
import EmpMasterChangeStatus from "../EmploymentMasterPage/EmpMasterChangeStatus";
import EmployeeMasterSuccess from "../EmploymentMasterPage/EmployeeMasterSuccess";
import AddInvestigator from "../AddInvestigator";
import Onboarding from "../Onboarding";
import AllottedCases from "../AllottedCases";
import OpenCasesDetailsPage from "../OpenCasesDetailsPage";
import AssignInvestigator from "../AssignInvestigator";
import AllottedCasesDetailsPage from "../AllottedCasesDetailsPage";
import AssignInvestigatorSuccess from "../AssignInvestigator/AssignInvestigatorSuccess";
import ReinvestigateCases from "../ReinvestigateCases";
import ReinvestigateCasesDetails from "../ReinvestigateCasesDetails";
import AssignedBlocksAllottedCases from "../AssignedBlocksAllottedCases";
import AssignedBlocksOpenCases from "../AssignedBlocksOpenCases";
import AssignBlocksReinvestigatedCases from "../AssignBlocksReinvestigatedCases";
import RejectedInvoices from "../RejectedInvoices";
import RejectedInvoicesDetailsPage from '../RejectedInvoicesDetailsPage';
import AddNewInvoice from "../AddNewInvoice";
import SubmitFinalPage  from "../SubmitFinalPage";
import UploadInvestigationReport  from "../UploadInvestigationReport";
import UploadInvoice from "../UploadInvoice";
import UploadedInvoicePreview from "../UploadedInvoicePreview";
import UploadInvoiceDetails from "../UploadInvoiceDetails";
import GenerateFactFindingReport from "../GenerateFactFindingReport";
import ComingSoonPage from "../../components/ComingSoonPage";
import PerformanceReportPage from "../PerformanceReportPage";
import SuccessPage from "../SuccessPage";
import ProfileScreen from "../ProfileScreen/Loadable";
import SearchCase from "../SearchCase/Loadable";
import AddNewLocation from "../AddNewLocation";
import ServiceLocations from "../ServiceLocations/Loadable";
import DocumentCenter from "../DocumentCenter/Loadable";
import ProfileViewDocument from "../ProfileViewDocument/Loadable";
import CasesAndInvoiceTracker from "../CasesAndInvoiceTracker/Loadable";
import DoNotAcceptOpenCases from "../DoNotAcceptOpenCases/Loadable";
import DoNotAcceptSuccess from "../DoNotAcceptOpenCases/DoNotAcceptSuccess/DoNotAcceptSuccess";
import PageNotFound from "../PageNotFound/Loadable";
import InvoiceDetailsAndStatus from "../InvoiceDetailsAndStatus/InvoiceDetailsAndStatus";
import RegulatoryDetails from "../RegulatoryDetails";
//react-gtm-module for google tag manager
import TagManager from "react-gtm-module";
// add google tag  manager container id here
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID, // GTM ID for testing
};
TagManager.initialize(tagManagerArgs);

export default function App() {
  return (
    <div className="app-wrapper">
      <Helmet titleTemplate="%s - CHASE" defaultTitle="CHASE">
        <meta name="description" content="A CHASE application" />
      </Helmet>
      <Routes>
        <Route exact path={path.HOME} element={<><Header/><HomePage/><Footer/></>} />
        <Route exact path={path.OTP} element={<><Header/><OtpPage/><Footer/></>} />
        <Route exact path={path.MOBILE} element={<><Header/><Mobile/><Footer/></>} />
        <Route exact path={path.ONBOARDING} element={<PrivatePath><Onboarding /></PrivatePath>} />
        <Route exact path={path.DASHBOARD} element={<PrivatePath><Dashboard/></PrivatePath>} />
        <Route exact path={path.MESSAGE} element={<PrivatePath><NavBar /><Message /></PrivatePath>} />
        <Route exact path={path.NOTIFICATION} element={<PrivatePath><Notification /></PrivatePath>} />
        <Route exact path={path.OPEN_CASE} element={<PrivatePath><CasesDetails /></PrivatePath>} />
        <Route exact path={path.OPEN_CASE_DETAILS} element={<PrivatePath><OpenCasesDetailsPage /></PrivatePath>} />
        <Route exact path={path.ALLOTED_CASE_DETAILS} element={<PrivatePath><AllottedCasesDetailsPage /></PrivatePath>} />
        <Route exact path={path.EMPLOYMENT_MASTER} element={<PrivatePath><EmploymentMasterPage/></PrivatePath>} />
        <Route exact path={path.EMPLOYEMENT_SEARCH} element={<PrivatePath><SearchEmpPage /></PrivatePath> }/>
        <Route exact path={path.EDITEMPLOYEE_DETAILS} element={<PrivatePath><EditEmployeeDetails/></PrivatePath>} />
        <Route exact path={path.ALLOTTED_CASES} element={<PrivatePath><AllottedCases/></PrivatePath>} />
        <Route exact path={path.REJECTED_INVOICES} element={<PrivatePath><RejectedInvoices/></PrivatePath>} />
        <Route exact path={path.EMPLOYEMENT_CHANGESTATUS} element={<PrivatePath><EmpMasterChangeStatus /></PrivatePath>} />
        <Route exact path={path.ADD_INVESTIGATOR} element={<PrivatePath><AddInvestigator /></PrivatePath>} />
        <Route exact path={path.EMPLOYEMENT_SUCCESS} element={<PrivatePath><EmployeeMasterSuccess /></PrivatePath>} />
        <Route exact path={path.ASSIGN_INVESTIGATOR} element={<PrivatePath><AssignInvestigator /></PrivatePath>} />
        <Route exact path={path.REJECTED_INVOICES_DETAILS} element={<PrivatePath><RejectedInvoicesDetailsPage /></PrivatePath>} />
        <Route exact path={path.ASSIGN_INVESTIGATOR_SUCCESS} element={<PrivatePath><AssignInvestigatorSuccess /></PrivatePath>} />
        <Route exact path={path.ADD_NEW_LOCATION} element={<PrivatePath><AddNewLocation /></PrivatePath>} />
        <Route exact path={path.REINVESTIGATE_CASES} element={<PrivatePath><ReinvestigateCases /></PrivatePath>} />
        <Route exact path={path.REINVESTIGATE_CASES_DETAILS} element={<PrivatePath><ReinvestigateCasesDetails /></PrivatePath>} />
        <Route exact path={path.ASSIGNED_BLOCCKS_ALLOTTED_CASES} element={<PrivatePath><AssignedBlocksAllottedCases /></PrivatePath> } />
        <Route exact path={path.ASSIGNED_BLOCKS_OPEN_CASES} element={<PrivatePath><AssignedBlocksOpenCases /></PrivatePath> }/>
        <Route exact path={path.ASSIGNED_BLOCKS_REINVESTIGATED_CASES} element={<PrivatePath><AssignBlocksReinvestigatedCases /></PrivatePath>  }/>
        <Route exact path={path.ADD_NEW_INVOICE} element={ <PrivatePath><AddNewInvoice /></PrivatePath>} />
        <Route exact path={path.SUBMIT_FINAL} element={<PrivatePath><SubmitFinalPage /></PrivatePath>} />
        <Route exact path={path.UPLOAD_INVESTIGATION_REPORT} element={<PrivatePath><UploadInvestigationReport /></PrivatePath>} />
        <Route exact path={path.UPLOAD_INVOICE} element={<PrivatePath><UploadInvoice /> </PrivatePath>} />
        <Route exact path={path.UPLOAD_INVOICE_DETAILS} element={<PrivatePath><UploadInvoiceDetails /></PrivatePath>} />
        <Route exact path={path.PREVIEW_UPLOADED_INVOICES} element={<PrivatePath><UploadedInvoicePreview /></PrivatePath>} />
        <Route exact path={path.GENERATE_FACT_FINDING_REPORT} element={<PrivatePath><GenerateFactFindingReport /></PrivatePath>} />
        <Route exact path={path.COMING_SOON} element={<PrivatePath><ComingSoonPage /></PrivatePath>} />
        <Route exact path={path.PERFORMANCE_REPORT} element={<PrivatePath><PerformanceReportPage /> </PrivatePath>} />
        <Route exact path={path.SUCCESS_PAGE} element={<PrivatePath><SuccessPage /></PrivatePath>} />
        <Route exact path={path.PROFILE_PAGE} element={<PrivatePath><ProfileScreen /></PrivatePath>} />
        <Route exact path={path.SEARCH_CASE} element={<PrivatePath><SearchCase /></PrivatePath>} />
        <Route exact path={path.SERVICE_LOCATIONS} element={<PrivatePath><ServiceLocations /></PrivatePath>} />
        <Route exact path={path.DOCUMENT_CENTER} element={<PrivatePath><DocumentCenter /></PrivatePath>} />
        <Route exact path={path.PROFILE_VIEW_DOC} element={<PrivatePath><ProfileViewDocument /></PrivatePath>} />
        <Route exact path={path.CASES_AND_INVOICES_TRACKER} element={<PrivatePath><CasesAndInvoiceTracker /></PrivatePath>} />
        <Route exact path={path.INVOICE_DETAILS_AND_STATUS} element={<PrivatePath><InvoiceDetailsAndStatus /></PrivatePath>} />
        <Route exact path={path.REGULATORY_DETAILS} element={<PrivatePath><RegulatoryDetails /></PrivatePath>} />
        <Route exact path={path.DO_NOT_ACCEPT_CASE} element={<PrivatePath><DoNotAcceptOpenCases /></PrivatePath>} />
        <Route exact path={path.DO_NOT_ACCEPT_SUCCESS} element={<PrivatePath><DoNotAcceptSuccess /></PrivatePath>} />
        <Route  path={path.REGULATORY_DETAILS} element={<PrivatePath><RegulatoryDetails /></PrivatePath>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <GlobalStyle />
    </div>
  );
}
