import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectReinvestigateCasesDetailsDomain = state =>
  state.reinvestigateCasesDetails || initialState;

const makeSelectReinvestigateCasesDetails = () =>
  createSelector(
    selectReinvestigateCasesDetailsDomain,
    substate => substate
  );

  const makeSelectCaseReInvestigateData = () =>
  createSelector(
    selectReinvestigateCasesDetailsDomain,
    substate => substate.caseReinvestigateDetails
  );

export default makeSelectReinvestigateCasesDetails;
export { selectReinvestigateCasesDetailsDomain,makeSelectCaseReInvestigateData };
